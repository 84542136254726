import React from 'react'
import Player from './player'
import {Box,Text} from '@chakra-ui/react'


const TonDokumente3 =()=>{
return(
   
   
   
    <Box px="2" pb="2" borderLeft="4px gray solid">
    <Player url="/audio/segenspruch.mp3" />
        <Text>
           Segenspruch zum Kerzenanzünden 
        </Text>
      </Box> 
       


)
}
export default TonDokumente3