import React from 'react'
import {Heading,SimpleGrid,Box,Text} from '@chakra-ui/react'
import {StaticImage} from 'gatsby-plugin-image'
import {motion} from 'framer-motion'
//import {Link} from 'gatsby'
import MeinSeo from '../components/seo'
import TonDokumente3 from '../components/tondokumente3'

export default function Beitrag8(){

return(
  <motion.div
  initial={{opacity:0,scale:.7,paddingBottom:"100px"}}
  animate={{opacity:1,scale:1}}
  transition={{duration:.8,delay:.1}}
  >

  <MeinSeo title="Schabbes " description='Schabbes'/>



<Heading   variant="Titel">
Schabbes
</Heading>
<SimpleGrid columns={[1,1,2]} gap={5} >
  <Box>
  
<Text variant='solid'>
Das Schaufenster in der Oberstraße 42 gewährt einen Blick auf diesen ganz besonderen Feiertag: Kein anderer Tag spielt im Leben einer jüdischen Familie eine ähnlich große Rolle wie der Schabbat, in unserer Region meist „Schabbes“ genannt 
</Text>
{/* <motion.div
  initial={{opacity:0,scale:.7}}
  animate={{opacity:1,scale:1,y:20}}
  transition={{duration:1,delay:.3}}
  > */}

<Box p="2" mt="1">

  <Text pb="1.5" fontWeight="400"  lineHeight="1" fontSize={["3xl","4xl","4xl"]}>
  "Die Juden haben den Schabbat gehalten und der Schabbat hat die Juden erhalten.“
 </Text>
 <Text variant="Bild">Jüdische Volksweisheit</Text>
  </Box>
  {/* </motion.div> */}
  
<Text variant="solid">
Der Schabbat ist der siebte Tag der Woche und erinnert an das Ruhen Gottes nach der Erschaffung der Welt, aber auch an den Auszug des Volkes Israel aus Ägypten. Der Schabbat beginnt am Freitag- und endet am Samstagabend. In fast allen Erzählungen von Zeitzeugen unserer Region wird immer wieder die Würde und Heiligkeit dieses Tages betont und wie er den Zusammenhalt der jüdischen Familie symbolisiert.
</Text>
<Text variant="solid">
Zu den religiösen Geboten dieses Tages zählt das Einhalten der Schabbatruhe, die vorsieht, keinerlei Arbeit zu verrichten, nichts Neues zu erschaffen und die Welt nicht zu verändern. Dies umfasst z.B. folgende Tätigkeiten: Feuer entfachen, Licht anzünden, Lasten tragen, kochen, backen, reisen und längere Wegstrecken zurücklegen oder elektrische Geräte bedienen. Stattdessen ist der Schabbat ganz der Nähe zu Gott, dem Vortrag der Thora und der Pflege der Familie gewidmet. 
</Text>
<Text variant="solid">Sorgfältig werden daher zuvor die nötigen Vorbereitungen getroffen: Das Haus wird gesäubert, Einkäufe erledigt, Mahlzeiten vorgekocht und weiteren häuslichen Tätigkeiten vorgebeugt. Die traditionelle jüdische Schabbatfeier beginnt bei Einbruch der Dämmerung. Die Frau des Hauses entzündet die beiden Schabbat-Kerzen und spricht den zugehörigen Segen, um Friede und Licht in das Haus einzuladen.</Text>

<Box mb="5" fontStyle="italic">
  <Text pb="2" fontStyle="normal">Segensspruch zum Kerzenentzünden:</Text>
  <Text>Transliteration: Baruch ata Ado-naj,<br/>Elohenu Melech Ha’Olam,<br/>ascher kideschanu bemizwotaw<br/>weziwanu lehadlik ner schel Schabbat,<br/>Amen<br/><br/>Übersetzung: Gesegnet bist du mein Herr,<br/>Unser Gott, König der Welt<br/>Der uns in seinen Geboten segnete<br/>und uns befahl eine Kerze des Schabbats zu zünden</Text>

</Box>
<TonDokumente3 url="/audio/segenspruch.mp3" />
<Text variant='solid'>Der Schabbat wird eingeläutet, indem der Mann des Hauses den Kiddusch (von Hebräisch kadosch, „heilig“) spricht. Er nimmt einen bis zum Rand gefüllten, schönen Becher mit koscherem Wein zur Hand, segnet diesen und verteilt ihn unter den Anwesenden. Rituelle Bedeutung kommt außerdem dem Schabbat-Brot zu, zwei weiche, geflochtene Hefezöpfe, die als Challot bezeichnet werden. Auch sie werden gesegnet, in Stücke gebrochen, mit Salz bestreut und ebenfalls verteilt.</Text>
<Text variant='solid'>Den Ausgang des Schabbats am Samstagabend markiert die Hawdala-Zeremonie, die einen sanften Übergang zwischen Feiertag und Alltäglichem schaffen soll. Neben dem Segen über Licht und Wein, sind aromatische Kräuter oder Gewürze für das Ritual von besonderer Bedeutung. Ihr Duft soll eingeatmet werden und damit darüber hinwegtrösten, dass der Schabbat zu Ende geht. Zugleich soll der wohltuende Geruch Kraft und Hoffnung für die kommende Woche schenken.</Text>
<Text variant='solid'>Wie andere jüdische Feiertage ist auch der Schabbat mit traditionsreichen Speisen verbunden. Im Jiddischen bezeichnet der Schalet einen üblicherweise am Schabbat verzehrten Auflauf aus Kartoffel, Fleisch und Ei. Da am Ruhetag kein Feuer gezündet werden darf, wurde die Speise bereits freitags vor Schabbatbeginn vorbereitet und bei geringer Hitze bis zum Samstagmittag im Ofen fertig gegart. Noch heute erfreut sich der Schales, ein im Tiegel gebackener, dicker Kartoffelreibekuchen, im Hunsrück auch bei Nicht-Jüdinnen und Nicht-Juden großer Beliebtheit.</Text>

</Box>

<Box pt="2" px="3">
<Box  py="3">
                    
                    <StaticImage src="../images/solo/schabbes1.jpeg" alt="Bild vom roten Fenster" />
                    <Text variant='Bild'> ©Forst-Mayer Studien- und Begegnungszentrum<br/> Am festlich gedeckten Tisch beginnt der Feiertag.</Text>
                     
                </Box>
                <Box  p="0">
                    
                    <StaticImage src="../images/solo/schabbes2.jpeg" alt="Bild vom roten Fenster" />
                     <Text variant="Bild">  ©Forst-Mayer Studien- und Begegnungszentrum<br/>Mit dem Entzünden der Kerzen, dem Segen über Wein und Brot wird gemeinsam der Schabbat begrüßt.
 </Text>
                </Box>
               
</Box>
</SimpleGrid>
  
       </motion.div>
    )
}